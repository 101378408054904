.tsc-month {
  display: flex;
  flex-direction: column;
  background: $month-bg-color;
}

.tsc-month__actions {
  display: flex
}

.tsc-month__action {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  color: $day-default-color;
  font-family: $day-fonts;
  text-align: center;
}

.tsc-month__action-element {
  font-size: 1.5em;
  padding: 0 1em;
  cursor: pointer;
}

.tsc-month__action-title {
  flex-grow: 2;
  padding: 1em;
}
