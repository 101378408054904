$color-clouds: #ecf0f1;
$color-main-orange: #FF6235;
$color-main-black: #313232;
$color-white: #FFFFFF;
$default-fonts: 'Open Sans', sans-serif;
/**
 * Timeslot Component variables:
 */
$timeslot-default-bg-color: $color-white;
$timeslot-selected-bg-color: #FF3E00;
$timeslot-disabled-bg-color: #EEEDEB;
$timeslot-default-color: $color-main-black;
$timeslot-selected-color: $color-white;
$timeslot-disabled-color: #C6C3BD;
$timeslot-border-radius: 0.25em;
$timeslot-margin: 0.8em;
$timeslot-fonts: 'Open Sans', sans-serif;

/**
 * Day Component variables
 */
$day-default-color: $color-main-orange;
$day-fonts: 'Open Sans', sans-serif;

/**
 * Month Variables
 */
$month-bg-color: #F2F1EF;
/**
 * Calendar variables
 */
$calendar-bg-color: #F2F1EF;
$color-clouds: #ecf0f1;
